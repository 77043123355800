import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../../assets/logo/logo1.webp';
  
function NavbarPercheInvestireInOro() {
  
  const scrollButton = () => {
    const contactForm = document.querySelector("#contact-form");
    contactForm.scrollIntoView({ behavior: "smooth" });
  };

  const scrollTop = () => {
    const goTop = document.querySelector("#landingPercheInvestireInOro");
    goTop.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
       <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-light shadow rounded-bottom" id="">
            <div className="container" id="navbarGP">
                <Link className="navbar-brand" to="/perche-investire-in-oro/" onClick={scrollTop}>
                  <img className="img-fluid l-size" src={Logo} alt="logo" />
                </Link>
                <a href="#contact-form" className="border-0 ms-auto text-decoration-none bg-transparent cta-navbar" onClick={scrollButton}>
                  <i className="d-block d-md-none text-default display-3 fa-solid fa-envelope cta-navbar"></i>
                  <p className="d-none d-md-block bg-default rounded text-dark text-opacity-75 fw-bold p-2 m-0 cta-navbar">Scopri tutte le nostre opportunità</p>
                </a>
            </div>
        </nav>
    </div>
  )

}

export default NavbarPercheInvestireInOro
