import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
/* import React, { useRef, useState } from "react"; */
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Parallax, Pagination } from "swiper/modules";

export default function App() {

  return (
    <>
      <Swiper
        style={{
          "padding-top": "5rem",
      }}
        speed={600}
        parallax={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[ Autoplay, Parallax, Pagination ]}
        className="mySwiper">
        <div
          slot="container-start"
          className="parallax-bg header-custom"
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide>
          <div className="row d-flex justify-content-center align-items-center margin-custom-swiper-desktop">
            <div className="col-6 bg-light p-5 my-5" data-swiper-parallax="-300">
              <p className="text-default fw-bold fs-1 lh-base m-0 text-center">La sicurezza del domani</p>
              <p className="text-dark fs-4  text-opacity-75 m-0 lh-base text-center"><em>parte da un investimento pensato oggi</em></p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row d-flex justify-content-center align-items-center margin-custom-swiper-desktop">
            <div className="col-6 bg-light p-5 my-5" data-swiper-parallax="-200">
              <p className="text-default fw-bold fs-1 lh-base m-0 text-center">Esci dal buio dell'incertezza</p>
              <p className="text-dark fs-4  text-opacity-75 m-0 lh-base text-center"><em>Scegli la luce dell'oro</em></p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row d-flex justify-content-center align-items-center margin-custom-swiper-desktop">
            <div className="col-6 bg-light p-5 my-5" data-swiper-parallax="-100">
              <p className="text-default fw-bold fs-1 lh-base m-0 text-center">Chi pensa al futuro</p>
              <p className="text-dark fs-4  text-opacity-75 m-0 lh-base text-center"><em>si affida a Careisgold</em></p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
