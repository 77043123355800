import React, { useEffect, useState } from "react";
import SwiperHome from './components/SwiperHome'
import SwiperHomeDesktop from './components/SwiperHomeDesktop'
import CallToAction from '../../components/CallToAction'
import ContactForm from "../../components/ContactForm";
import OptimizeListItem from './components/OptimizeListItem'
import OptimizeListItem1 from './components/OptimizeListItem1'
import Reviews from './components/stepMobile'
import ReviewsDesktop from './components/stepDesktop'
import image2 from '../../assets/landing/landingPercheInvestireInOro/sede.jpg'
import AOS from "aos";
import "aos/dist/aos.css";
import "./landingPercheInvestireInOro.css";
import Navbar from './components/NavbarPercheInvestireInOro';
import Footer from '../../components/Footer';



function PercheInvestireInOro() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  const scrollButton = () => {
    const contactForm = document.querySelector("#contact-form");
    contactForm.scrollIntoView({ behavior: "smooth" });
  };

  const breakpointSmall = 576;
  const breakpointMedium = 768;

    const [section1, setSection] = useState(<p>Loading...</p>);
    useEffect(() => {
      function updateSection1() {
        if (window.innerWidth <= breakpointMedium) {
          setSection(<SwiperHome/>);
        } else {
          setSection(<SwiperHomeDesktop/>);
        }
      }
      window.addEventListener("resize", updateSection1);
      updateSection1();
      return () => window.removeEventListener("resize", updateSection1);
    }, []);

    const [section2, setSection2] = useState(<p>Loading...</p>);
    useEffect(() => {
      function updateSection2() {
        if (window.innerWidth <= breakpointMedium) {
          setSection2(<Reviews/>);
        } else {
          setSection2(<ReviewsDesktop/>);
        }
      }
      window.addEventListener("resize", updateSection2);
      updateSection2();
      return () => window.removeEventListener("resize", updateSection2);
    }, []);


    const [section3, setSection3] = useState(<p>Loading...</p>);
    useEffect(() => {
      function updateSection3() {
        if (window.innerWidth <= breakpointSmall) {
          setSection3(<OptimizeListItem/>);
        } else {
          setSection3(<OptimizeListItem1/>);
        }
      }
      window.addEventListener("resize", updateSection3);
      updateSection3();
      return () => window.removeEventListener("resize", updateSection3);
    }, []);
  
  return (
    <div id="landingPercheInvestireInOro">
      <Navbar/>
      <section className="p-0">
        {section1}
      </section>

      <div className="container-fluid bg-light">
        <div className="bg-secondary bg-opacity-10 row justify-content-center align-items-center py-md-4">
          <div className="row d-flex justify-content-center align-items-center py-4">
            <div className="col-12 text-center">
            <p className="text-default fs-3 fw-bold text-center m-0 p-0 text-uppercase">Non perdere l'opportunità di investire in oro puro fisico</p>
            <button onClick={scrollButton} className="border-0 bg-transparent ms-auto down-arrow">
              <i className="fa-solid fa-chevron-down text-default display-1"></i>
            </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
          <div className="bg-secondary bg-opacity-10 row justify-content-center align-items-center pb-4">
            <div className="col-11">
              <section className="p-0">
                {section2}
              </section>  
            </div>
          </div>    
      </div>

      <div className="container-fluid bg-light">
        <div className="container py-md-5 py-2">
          <div className="row d-flex justify-content-around align-items-center">
            <div className="col-12 col-md-5 pb-md-0 px-0">
              <img className="img-fluid rounded" src={image2} alt="immagine sede"/>
            </div>
            <div className="col-11 col-md-5 mt-3 pb-md-0 pb-0">
              <p className="text-default fw-bold fs-1 text-center text-md-center m-0">Chi è Careisgold?</p>
              <p className="text-center lh-base m-0 ps-2 ps-md-0 text-dark">Careisgold è la società per azioni del Gruppo
              Care Holding SpA che <span className="fw-bold text-black text-opacity-75">offre ai propri clienti la
              possibilità di diventare proprietari di Oro Puro
              Fisico</span> da investimento con semplicità e sicurezza.</p>
              <div className="col-md-10 col-12 mx-auto">
                <ul className="my-4 text-dark">
                  <li>
                  È iscritta al registro di Banca d’Italia con codice Operatore Professionale Oro n. 5008578;
                  </li>
                  <li>
                  dispone della Licenza di Pubblica Sicurezza rilasciata dalla Questura;
                  </li>
                  <li>
                  ha siglato accordi esclusivi con le più prestigiose raffinerie di Oro Puro Fisico da investimento a garanzia del
                  miglior rapporto qualità prezzo;
                  </li>
                  <li>
                  ha un capitale sociale interamente versato di 2.000.000,00 €;
                  </li>
                </ul>  
              </div>
            </div>
          </div>
        </div>         
      </div>

    <div className="container">
      <div className="row d-flex justify-content-center align-items-center bg-oro">
        <div className="col-md-7 col-11 text-center mt-4">
          <p className="text-default fw-bold fs-2">I nostri piani in Oro soddisfano
            qualsiasi esigenza</p>
        </div>
        
        <div className="col-md-7 col-11 text-center bg-light piani-rounded my-4">
          <p className="text-dark m-0 py-4 fw-bold fs-5"><span className="text-default fs-1">PAR</span> <br/> Piano d'acquisto ricorrente</p>
          <p className="text-dark m-0 pb-4">Piano di acquisto ricorrente con <span className="text-black text-opacity-75 fw-bold">versamenti
            programmati e personalizzabili.</span> <br/> Consente
            di acquistare la grammatura scelta, nei
            tempi desiderati.</p>
        </div>
        <div className="col-md-7 col-11 text-center bg-light piani-rounded my-4">
          <p className="text-dark m-0 py-4 fw-bold fs-5"><span className="text-default fs-1">AUrum</span> <br/> Acquisto unico</p>
          <p className="text-dark m-0 pb-4">Un prodotto ideale per chi preferisce
            una <span className="text-black fw-bold text-opacity-75">soluzione «una tantum».</span></p>
        </div>
        <div className="col-md-7 col-11 text-center bg-light piani-rounded m-4 mb-5">
          <p className="text-dark m-0 py-4 fw-bold fs-5"><span className="text-default fs-1">AUrum Extra</span> <br/> Acquisto unico + versamenti mensili</p>
          <p className="text-dark m-0 pb-4">La soluzione combinata AUrum
            Extra offre la possibilità di
            suddividere il proprio investimento
            in Oro Puro Fisico acquistandone una parte in un’unica e immediata soluzione e l’altra parte attraverso <span className="text-black text-opacity-75 fw-bold">comodi versamenti mensili.</span></p>
        </div>
        <div className="col-md-7 col-11 text-center">
          <button className="bg-default border-0 text-light button-rounded mb-5 py-2 px-4 fw-normal fs-5" onClick={scrollButton} id="CTA2">Contattaci</button>
        </div>
      </div>
    </div>

    {section3}
  
      <div className="container-fluid bg-light">
        <div className="row d-flex justify-content-center align-items-center">
            <div className="">
            <p className="pt-4 fs-4 fw-bold text-center text-default m-0"><em>Investire in oro fisico:<br/>la scelta vincente.</em></p>
            <p className="text-default fw-bold fs-1 text-center pt-4">CONTATTACI</p>
            </div>
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 col-12 m-0 p-0">
            <ContactForm 
                NomeLanding='Landing perché investire in oro'
                Tipo='Cliente'
                BaseInputs={[    
                    { name: 'nome', required: true},
                    { name: 'cognome', required: true},
                    { name: 'email', required: true},
                    { name: 'telefono', required: true},
                ]}
                City={[{
                     city: true, 
                     required: true,
                     cityName: 'Città',
                     placeholder: 'Città'
                }]}
                DropDown={[{
                    dropDown: true, 
                    title:"Interessato/a a:", 
                    defaultOption: "Le nostre soluzioni", 
                    disabled: false, 
                    option1: 'Collabora con noi',
                    option2: 'altro',
                    option3: '',
                    required: false
                }]}
                Vote={[{
                    vote: false, 
                    name: "Quanto ti è piaciuto l'evento?", 
                    message: true, 
                    placeholder: 'Raccontaci la tua esperienza',
                    required: false
                }]}
                Message={[{
                    message: true, 
                    required: false,
                    messageName: 'Messaggio',
                    placeholder: 'altro...'
                }]}
                ConsensoDati={[{
                    consensoDati: true, 
                    required: true,
                    idPrivacy: 1
                }]}
                ConsensoNewsletter={[{
                    consensoNewsletter: false, 
                    listId: 14,
                    required: false,
                    idPrivacy: 3
                }]}
                SubmitButton={[{
                    submitName:'Invia', 
                    submittedName:'Inviato',
                    submittingName:'Sto inviando...'
                }]}
                SuccessMessage={[{
                    successMessage:'La tua richiesta è stata inoltrata con successo!', 
                    successButton:'Visita il nostro sito!', 
                    url:'https://www.careisgold.it/'
                }]}
            />
          </div>
        </div>
      </div>
        <CallToAction/>    
        <Footer/>
    </div>
  )
}

export default PercheInvestireInOro
