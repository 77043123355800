import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PercheInvestireInOro from "./landing/percheInvestireInOro/LandingPercheInvestireInOro";
import "./index.css";


function App() {

  return (
    <div className="App">
        <Router>
            <Routes>
              {/* main routes */}
                <Route path="/*" element={<Navigate to="/perche-investire-in-oro" replace/>} />
                <Route exact path="/perche-investire-in-oro" element={<PercheInvestireInOro />} />
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
