import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import step1 from '../../../assets/landing/landingPercheInvestireInOro/step-1.png';
import step2 from '../../../assets/landing/landingPercheInvestireInOro/step-2.png';
import step3 from '../../../assets/landing/landingPercheInvestireInOro/step-3.png';
import step4 from '../../../assets/landing/landingPercheInvestireInOro/step-4.png';



export default function App() {

  const [expandedId, setExpandedId] = useState(null);
  const swiperRef = useRef(null);

  const handleClick = (i) => {
    setExpandedId(expandedId === i ? null : i);
    swiperRef.current.swiper.autoplay.stop();
  };

  function openAtTheEnd(){
    setExpandedId(null)
  }

  return (
       <>
      <Swiper
        ref={swiperRef}
        onSlideChange={openAtTheEnd}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        navigation={true}
        modules={[ Navigation, Autoplay]}
        className="mySwiper"
      >
   
          <div className="container">            

               <SwiperSlide className="text-dark text-center">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-content-center text-center">
                    <div className="bg-dark bg-opacity-50 rounded-circle" style={{"height":"125px", "width":"125px"}}>
                      <img className="img-fluid icon p-3" src={step1} alt="icona consulenza"/>
                    </div>
                  </div>
                    <div className="col-9 col-md-10 mt-3">
                        <p className="text-default m-0 fw-bold fs-2">STEP 1</p>
                        <p className="text-dark m-0 fw-bold fs-4">Consulenza a domicilio</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center mt-3">
                  <div className="col-10 text-center">
                    <p  onClick={() => handleClick(1)} className={expandedId === (1) ? "expanded text-dark" : "collapsed text-dark"}>
                      La consulenza e la proposizione dell’offerta vengono effettuate presso il domicilio del cliente e conformemente alle sue necessità.
                    </p>
                  </div>
                </div>
               </SwiperSlide>
               <SwiperSlide className="text-dark text-center">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-content-center text-center">
                    <div className="bg-dark bg-opacity-50 rounded-circle" style={{"height":"125px", "width":"125px"}}>
                      <img className="img-fluid icon p-3" src={step2} alt="icona consulenza"/>
                    </div>
                  </div>
                    <div className="col-9 col-md-10 mt-3">
                        <p className="text-default m-0 fw-bold fs-2">STEP 2</p>
                        <p className="text-dark m-0 fw-bold fs-4">Acquisto Oro</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center mt-3">
                  <div className="col-10 text-center">
                    <p  onClick={() => handleClick(2)} className={expandedId === (2) ? "expanded text-dark" : "collapsed text-dark"}>
                    Una volta ricevuto l’ordine da parte del cliente, ci occupiamo dell’acquisto e dello
                    stoccaggio dell’Oro e liberiamo il cliente da
                    qualsiasi incombenza burocratica.
                    </p>
                  </div>
                </div>
               </SwiperSlide>
               <SwiperSlide className="text-dark text-center">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-content-center text-center">
                    <div className="bg-dark bg-opacity-50 rounded-circle" style={{"height":"125px", "width":"125px"}}>
                      <img className="img-fluid icon p-3" src={step3} alt="icona consulenza"/>
                    </div>
                  </div>
                    <div className="col-9 col-md-10 mt-3">
                        <p className="text-default m-0 fw-bold fs-2">STEP 3</p>
                        <p className="text-dark m-0 fw-bold fs-4">Custodia</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center mt-3">
                  <div className="col-10 text-center">
                    <p  onClick={() => handleClick(3)} className={expandedId === (3) ? "expanded text-dark" : "collapsed text-dark"}>
                    L’Oro acquistato può
                    essere depositato presso
                    i caveaux Battistolli, i più
                    rinomati d’Italia. In
                    alternativa, organizziamo
                    il trasporto in 
                    totale sicurezza. 
                    </p>
                  </div>
                </div>
               </SwiperSlide>
               <SwiperSlide className="text-dark text-center">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-content-center text-center">
                    <div className="bg-dark bg-opacity-50 rounded-circle" style={{"height":"125px", "width":"125px"}}>
                      <img className="img-fluid icon p-3" src={step4} alt="icona consulenza"/>
                    </div>
                  </div>
                    <div className="col-9 col-md-10 mt-3">
                        <p className="text-default m-0 fw-bold fs-2">STEP 4</p>
                        <p className="text-dark m-0 fw-bold fs-4">Il Suo Oro</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center mt-3">
                  <div className="col-10 text-center">
                    <p  onClick={() => handleClick(4)} className={expandedId === (4) ? "expanded text-dark" : "collapsed text-dark"}>
                    Tramite la nostra App,
                    ogni cliente può
                    controllare lo stato del
                    proprio piano, i
                    versamenti effettuati, le
                    fatture emesse... e tanto

                    altro!
                    </p>
                  </div>
                </div>
               </SwiperSlide>

          </div>
      </Swiper>
    </>
  );
}


