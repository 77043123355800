import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";



function OptimizeListItem() {

    const [content, setContent] = useState(null);
    const [activeButton, setActiveButton] = useState(null);

    const handleClick1 = () => {
      document.getElementById("radio-1").checked = false;
      document.getElementById("radio-2").checked = true;
      if(activeButton !== "Button 1") {
        setActiveButton("Button 1");
        setContent(
          <div className="container bg-light">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 rounded text-center pt-4">
                <ul className="text-center p-0">
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                    <div className="col-2">
                      <i className="fa-regular fa-circle-check text-default fs-1"></i>
                    </div>
                    <div className="col-9 text-start fw-bold">
                      <p className="m-0">È UN BENE FISICO DI PROPRIETÀ DEL CLIENTE</p>
                    </div>
                    <div className="col-7 text-start fw-light">
                      <p className="m-0 text-dark">Chi lo acquista è il solo e unico proprietario.</p>
                    </div>
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">NON SI PUÒ PRODURRE ARTIFICIALMENTE</p>
                      </div>
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">NON È UNA RISORSA INFINITA</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">È DUREVOLE E INVARIABILE</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">Viene usato sin dai tempi della preistoria (oltre 6000 anni fa).</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">È UN ANTIDOTO ALL’INFLAZIONE</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">Mantiene il suo valore e il suo potere d’acquisto nel tempo.</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">È UTILIZZATO COME RISERVA MONETARIA</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">1/4 dell’Oro mondiale è detenuto da banche e governi e il trend dei loro acquisti è in costante aumento.</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">PROTEGGE IL PATRIMONIO</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">Difende da volatilità e scossoni dei mercati finanziari, non essendo legato all’andamento di azioni e obbligazioni.</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">È IL BENE RIFUGIO PER ECCELLENZA</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">Per eccellenza ed è in grado di sopravvivere a qualsiasi default di valuta.</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">È LIQUIDABILE IN TUTTO IL MONDO</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">In quanto il suo valore è riconosciuto universalmente.</p>
                      </div> 
                  </li>
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">È ESENTE IVA</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0 text-dark">È conveniente da un punto di vista fiscale sia in fase di acquisto, che di possesso, che di vendita.</p>
                      </div> 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      } 
    };
  
    const handleClick2 = () => {
      document.getElementById("radio-2").checked = false;
      document.getElementById("radio-1").checked = true;
      if(activeButton !== "Button 2") {
        setActiveButton("Button 2");
        setContent(
          <div className="container bg-light">
          <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 rounded text-center pt-4">
                <ul className="text-center p-0">

                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">ORO PURO CERTIFICATO DI PUREZZA 999,9/1000, 24 CARATI</p>
                      </div>
                  </li>

                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">OFFERTA PERSONALIZZATA E FLESSIBILE</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0">Soluzioni su misura create ad hoc per ciascun cliente, con possibilità di personalizzare durata, periodicità e cadenza dei versamenti.</p>
                      </div> 
                  </li>

                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">“GOOD DELIVERY”</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0">Garanzia di elevata qualità dell’Oro, che lo rende di immediato utilizzo e riconosciuto in tutto il mondo. Definisce lo standard qualitativo nel mercato dell’Oro.</p>
                      </div> 
                  </li>
                  
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">SICUREZZA</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0">L’Oro Puro Fisico di proprietà del cliente è custodito in caveaux di massima sicurezza, assicurato al 100% dai più grandi colossi assicurativi mondiali.</p>
                      </div> 
                  </li>
                  
                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">ORO NOMINALE</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0">L’Oro Puro Fisico è di esclusiva proprietà del cliente. Ogni lingotto è identificato all'interno del caveau mediante un codice seriale e un codice sigillo.</p>
                      </div> 
                  </li>

                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">POSSIBILITÀ DI RICEVERE L'ORO A CASA</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0">Il cliente può richiedere di ricevere l’Oro comodamente a casa, mediante un servizio di trasporto valori discreto e assicurato al 100%.</p>
                      </div> 
                  </li>

                  <li className="row d-flex justify-content-center align-items-center pt-3" >
                      <div className="col-2">
                        <i className="fa-regular fa-circle-check text-default fs-1"></i>
                      </div>
                      <div className="col-9 text-start fw-bold">
                        <p className="m-0">FILIERA ETICA E PERCORSO TRACCIATO</p>
                      </div>
                      <div className="col-7 text-start fw-light">
                        <p className="m-0">Percorso di estrazione e trasformazione certificato ed eticamente corretto.</p>
                      </div> 
                  </li>

                </ul>
              </div>
          </div>
        </div>
        );
      } 
    };

    useEffect(() => {
      AOS.init({ duration: 1500 });
      handleClick2();
      document.getElementById("radio-1").checked = true;
    }, []);

return(
        
<div>
  <div className="container-fluid bg-light">
    <div className="container">
        <div className="row pt-5 d-flex justify-content-center align-items-center">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <input type="radio" id="radio-1" name="tabs" onClick={handleClick2}/>
            <label className="tab text-center fw-bold d-flex align-items-center" for="radio-1">Perché scegliere Careisgold?</label>
            <input type="radio" id="radio-2" onClick={handleClick1} name="tabs" />
            <label className="tab text-center fw-bold d-flex align-items-center" for="radio-2">Perché investire nell'Oro fisico?</label>
            <span className="glider"></span>
          </div>
        </div>
    </div>
    {content}
  </div>
</div>

    );


}

export default OptimizeListItem